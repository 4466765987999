<template>
	<error-container :error="erreur" :warning="warning">
		<div
			:class="{
				'container-layout': $screen.width >= 992,
				'container-mobile': $screen.width < 992
			}"
			class="p-0"
		>
			<b-row class="form">
				<b-col cols="12">
					<h1 :class="[`${!$screen.sm && !$screen.md ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
						{{ FormMSG(73, 'Expense details') }}
					</h1>
					<b-card no-body style="background-color: #fff">
						<b-row
							class="back-with-title cursor-pointer d-flex align-items-center pt-0 pb-2 pl-0"
							:class="{ 'mt-16': $isPwa() }"
							v-if="$screen.width >= 992"
							style="margin-top: -6px"
						>
							<b-col cols="6">
								<button class="btn-transparent" @click="$router.push('/myexpenses')">
									<ArrowLeft :size="22" class="icon" color="rgba(6, 38, 62, 0.65)" />
									<span class="title-back-bolder mx-0"> {{ caption1 }}</span>
								</button>
							</b-col>
							<b-col cols="6" class="inline-flex align-items-center text-right py-0">
								<div class="header-date-title pr-3">
									<div class="wrap-status justify-content-end">
										<div :class="`status ${classStatus(curExp.validated)}`" style="font-size: 0.7rem">
											{{ labelStatus(curExp.validated) }}
										</div>
									</div>
								</div>
								<div class="name-title pr-3">{{ curExp.fullName }} <span class="divider-dot"></span> {{ curExp.functionName }}</div>
								<div v-if="useEntryForCrewExpenses" class="d-flex align-items-center justify-content-end w-100 pr-3">
									<span v-if="!isFilmSingle" class="border-encoded p-1">
										{{ FormMSG(149, 'Encoded by: ') }} <b>{{ curExp.encoder.firstName }} {{ curExp.encoder.name }}</b>
									</span>
								</div>
								<div class="header-name-title pr-3">
									{{ dateToStringFormat(curExp.date) }} <span class="divider-dot"></span>
									<strong>{{ displayCurrency(curExp.amountTotal) }}</strong>
								</div>
							</b-col>
						</b-row>
						<div v-else>
							<div class="back-with-title d-flex align-items-center cursor-pointer" :class="{ 'mt-16': $isPwa() }" @click="$router.go(-1)">
								<div class="icon">
									<component :is="getLucideIcon('ArrowLeft')" color="#667987" :size="22" />
								</div>
								<h2>{{ caption1 }}</h2>
							</div>
							<div class="header-title-exp-detail text-center py-2">
								<div class="header-date-title pr-3">
									<div class="wrap-status justify-content-end">
										<div :class="`status ${classStatus(curExp.validated)}`" style="font-size: 0.7rem">
											{{ labelStatus(curExp.validated) }}
										</div>
									</div>
								</div>
								<div class="header-name-title pr-3">{{ curExp.fullName }} <span class="divider-dot"></span> {{ curExp.functionName }}</div>
								<div class="header-name-title pr-3">
									{{ dateToStringFormat(curExp.date) }} <span class="divider-dot"></span> {{ displayCurrency(curExp.amountTotal) }}
								</div>
							</div>
						</div>
						<b-card-text :class="`${$screen.width >= 992 ? 'px-3 pt-4' : 'px-2 pt-3'}`">
							<div :class="`${$screen.width >= 992 ? 'card card-border-blue-light' : ''}`" v-if="curExp.comment.length > 0">
								<b-row>
									<b-col cols="12">
										<b-form-group :label="FormMSG(14, 'Comment:')" label-for="comment">
											<b-form-textarea :disabled="true" id="comment" v-model="curExp.comment" rows="2"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<div class="card card-border-blue-light mt-3" :style="`${$screen.width <= 992 ? 'margin-bottom: 112px;' : ''}`">
								<b-row>
									<b-col cols="12" xl="12">
										<div>
											<!--      for travel, ticket -->
											<CardListBuilder
												v-if="$screen.width < 992 && curExp.type < EXPENSE_TYPE.TRAVEL"
												:use-new-version="true"
												:items="expItems"
												:fields="omit(['validatedStatus'], ticketFields)"
												:has-badge="true"
												:is-badge-use-props="true"
												badge-class="statusClass"
												:hide-status="false"
												badge-value="validatedStatus"
												:toggle-mode="true"
											>
												<template slot="rej" slot-scope="data">
													<div class="d-flex justify-content-end align-items-center w-100">
														<component
															v-if="
																data.item.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
																data.item.validated === VALIDATION_TYPE.REFUSED_LEVEL_2
															"
															:is="getLucideIcon(ICONS.UNDO_2.name)"
															:color="ICONS.UNDO_2.color"
															:size="22"
															:stroke-width="2.5"
															@click="undoRejectExpenseItem(data.item.id)"
														/>
														<component
															v-else
															:is="getLucideIcon(ICONS.X_CIRCLE.name)"
															:color="ICONS.X_CIRCLE.color"
															:size="22"
															:stroke-width="2.5"
															@click="rejectExpenseItem(data.item.id)"
														/>
													</div>
												</template>
												<template slot="pict" slot-scope="data">
													<component
														v-if="data.item.images.length > 0"
														:is="getLucideIcon(ICONS.PICTURE.name)"
														:color="ICONS.PICTURE.color"
														:size="22"
														:stroke-width="2.5"
														@click="showPicture(data.item.images)"
													/>
												</template>
												<template slot="expenseItem" slot-scope="data">
													<component
														v-if="canEditExpense"
														:is="getLucideIcon(ICONS.EDIT.name)"
														:color="ICONS.EDIT.color"
														:size="20"
														@click="openEditExpense(data.item)"
													/>
												</template>
											</CardListBuilder>
											<!-- <div v-if="$screen.width >= 992 && curExp.type < EXPENSE_TYPE.TRAVEL" class="text-muted">
												<strong>* {{ FormMSG(222, 'Double click on row to have another view') }}</strong>
											</div> -->
											<data-table
												v-if="$screen.width >= 992 && curExp.type < EXPENSE_TYPE.TRAVEL"
												:value="expItems"
												selectionMode="single"
												showGridlines
												:resizableColumns="true"
												:scrollable="true"
												scrollDirection="horizontal"
											>
												<!-- @row-dblclick="onTicketRowDblclick" -->
												<div v-for="(field, index) in ticketFields" :key="index">
													<column
														v-if="!field.isDataTableSlot"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{
															'justify-content': 'center',
															'background-color': field.backgroundColor ? field.backgroundColor : 'none'
														}"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
														:frozen="field.key === 'id'"
													/>
													<column
														v-if="field.isDataTableSlot && field.key === 'validatedStatus'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ padding: 0, 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															<div class="wrap-status d-flex">
																<div
																	v-if="slotProps.data.isInContract === false"
																	class="justify-content-center align-items-center"
																>
																	<button
																		class="btn-transparent text-color-rhapsody-in-blue"
																		v-b-tooltip.left.html
																		:title="tooltipContentOvertime()"
																	>
																		<component :is="getLucideIcon('AlertTriangle')" color="#EA4E" :size="18" />
																	</button>
																</div>
																<div :class="`status ${slotProps.data.statusClass}`" style="font-size: 0.7rem" class="py-0">
																	{{ slotProps.data.validatedStatus }}
																</div>
															</div>
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'date'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ dateToStringFormat(slotProps.data.date) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'amount'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center', 'background-color': field.backgroundColor }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ displayCurrency(slotProps.data.amount) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'amountTotal'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center', 'background-color': field.backgroundColor }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ displayCurrency(slotProps.data.amountTotal) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'amountVat'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center', 'background-color': field.backgroundColor }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ displayCurrency(slotProps.data.amountVat) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'splitVat'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{
															'justify-content': field.class === 'text-center' ? 'center' : 'end',
															'padding-top': 0,
															'padding-bottom': 0
														}"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
														alignFrozen="right"
														frozen
													>
														<template #body="slotProps">
															<b-button class="btn bg-transparent border-0" size="sm">
																<component
																	:is="getLucideIcon(ICONS.PLUS_CIRCLE.name)"
																	color="#00a09c"
																	:size="20"
																	@click="onExpenseItemSplitted(slotProps)"
																/>
															</b-button>
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'kgCoTwo'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ displayKgCo2(slotProps.data.kgCoTwo) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'actions'"
														:exportable="false"
														:styles="{ padding: 0, 'flex-grow': '1', 'flex-basis': '12%' }"
														:bodyStyle="{ 'justify-content': 'center' }"
														:headerStyle="{ 'justify-content': 'center' }"
														alignFrozen="right"
														frozen
														header="Actions"
													>
														<template #body="slotProps">
															<div class="d-flex justify-content-center align-items-center w-100">
																<div
																	style="width: 33%; padding: 7px 0"
																	class="d-flex justify-content-center"
																	v-if="slotProps.data.images.length > 0"
																>
																	<b-button
																		class="btn bg-transparent border-0"
																		size="sm"
																		@click="showPicture(slotProps.data.images)"
																	>
																		<component
																			:is="getLucideIcon(ICONS.PICTURE.name)"
																			:color="ICONS.PICTURE.color"
																			:size="20"
																		/>
																	</b-button>
																</div>
																<div
																	style="
																		border-left: 0.005rem solid rgba(226, 230, 235, 0.85);
																		border-right: 0.005rem solid rgba(226, 230, 235, 0.85);
																		width: 34%;
																		padding: 7px 0;
																	"
																	:style="`${
																		slotProps.data.images.length === 0
																			? 'border-left: none !important; width: 50% !important'
																			: 'width: 34% !important'
																	}`"
																	class="d-flex justify-content-center"
																	v-if="canEditExpense"
																>
																	<b-button
																		class="btn bg-transparent border-0"
																		size="sm"
																		@click="openEditExpense(slotProps.data)"
																	>
																		<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
																	</b-button>
																</div>
																<div
																	style="width: 33%; padding: 7px 0"
																	class="d-flex justify-content-center"
																	:style="`${
																		slotProps.data.images.length === 0 ? 'width: 50% !important' : 'width: 33% !important'
																	}`"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-if="
																			slotProps.data.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
																			slotProps.data.validated === VALIDATION_TYPE.REFUSED_LEVEL_2
																		"
																		@click="undoRejectExpenseItem(slotProps.data.id)"
																	>
																		<component
																			:is="getLucideIcon(ICONS.UNDO_2.name)"
																			:color="ICONS.UNDO_2.color"
																			:size="20"
																		/>
																	</b-button>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		@click="rejectExpenseItem(slotProps.data.id)"
																		v-else
																	>
																		<component
																			:is="getLucideIcon(ICONS.X_CIRCLE.name)"
																			:color="ICONS.X_CIRCLE.color"
																			:size="20"
																		/>
																	</b-button>
																</div>
															</div>
														</template>
													</column>
												</div>
											</data-table>
											<!--      for km -->
											<CardListBuilder
												v-if="$screen.width < 992 && curExp.type === EXPENSE_TYPE.TRAVEL"
												:use-new-version="true"
												:items="expItems"
												:fields="omit(['validatedStatus'], kmFields)"
												:has-badge="true"
												:is-badge-use-props="true"
												badge-class="statusClass"
												:hide-status="false"
												badge-value="validatedStatus"
												:toggle-mode="true"
											>
												<template slot="rej" slot-scope="data">
													<div class="d-flex justify-content-end align-items-center w-100">
														<component
															v-if="
																data.item.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
																data.item.validated === VALIDATION_TYPE.REFUSED_LEVEL_2
															"
															:is="getLucideIcon(ICONS.UNDO_2.name)"
															:color="ICONS.UNDO_2.color"
															:size="22"
															:stroke-width="2.5"
															@click="undoRejectExpenseItem(data.item.id)"
														/>
														<component
															v-else
															:is="getLucideIcon(ICONS.X_CIRCLE.name)"
															:color="ICONS.X_CIRCLE.color"
															:size="22"
															:stroke-width="2.5"
															@click="rejectExpenseItem(data.item.id)"
														/>
													</div>
												</template>
												<template slot="expenseItem" slot-scope="data">
													<component
														v-if="canEditExpense"
														:is="getLucideIcon(ICONS.EDIT.name)"
														:color="ICONS.EDIT.color"
														:size="20"
														@click="openEditExpense(data.item)"
													/>
												</template>
											</CardListBuilder>
											<!-- <div v-if="$screen.width >= 992 && curExp.type === EXPENSE_TYPE.TRAVEL" class="text-muted">
												<strong>* {{ FormMSG(222, 'Double click on row to have another view') }}</strong>
											</div> -->
											<data-table
												v-if="$screen.width >= 992 && curExp.type === EXPENSE_TYPE.TRAVEL"
												:value="expItems"
												selectionMode="single"
												showGridlines
												:resizableColumns="true"
												:scrollable="true"
												scrollDirection="horizontal"
											>
												<!-- @row-dblclick="onTravelRowDblclick" -->
												<div v-for="(field, index) in kmFields" :key="index">
													<column
														v-if="!field.isDataTableSlot"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
														:frozen="field.key === 'id'"
													/>
													<column
														v-if="field.isDataTableSlot && field.key === 'validatedStatus'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ padding: 0, 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															<div class="wrap-status d-flex">
																<div
																	v-if="slotProps.data.isInContract === false"
																	class="justify-content-center align-items-center"
																>
																	<button
																		class="btn-transparent text-color-rhapsody-in-blue"
																		v-b-tooltip.left.html
																		:title="tooltipContentOvertime()"
																	>
																		<component :is="getLucideIcon('AlertTriangle')" color="#EA4E" :size="18" />
																	</button>
																</div>
																<div :class="`status ${slotProps.data.statusClass}`" style="font-size: 0.7rem" class="py-0">
																	{{ slotProps.data.validatedStatus }}
																</div>
															</div>
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'date'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ dateToStringFormat(slotProps.data.date) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'km'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ getDistanceTotalByUnit(slotProps.data.km) + ' ' + distanceUnit }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'kgCoTwo'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ displayKgCo2(slotProps.data.kgCoTwo) }}
														</template>
													</column>
													<column
														v-if="field.isDataTableSlot && field.key === 'amountTotal'"
														:field="field.key"
														:header="field.label"
														:sortable="field.sortable && field.sortable === true ? true : false"
														:bodyStyle="{ 'justify-content': field.class === 'text-center' ? 'center' : 'end' }"
														:headerStyle="{ 'justify-content': 'center' }"
														:styles="{ 'flex-grow': '1', 'flex-basis': field.flexBasis }"
													>
														<template #body="slotProps">
															{{ displayCurrency(slotProps.data.amountTotal) }}
														</template>
													</column>
												</div>
												<column
													:exportable="false"
													:styles="{ padding: 0, 'flex-grow': '1', 'flex-basis': '8%' }"
													:bodyStyle="{ 'justify-content': 'center' }"
													:headerStyle="{ 'justify-content': 'center' }"
													alignFrozen="right"
													frozen
													header="Actions"
												>
													<template #body="slotProps">
														<div class="d-flex justify-content-center align-items-center w-100">
															<div
																style="border-right: 0.005rem solid rgba(226, 230, 235, 0.85); width: 50%; padding: 7px 0"
																class="d-flex justify-content-center"
																v-if="canEditExpense"
															>
																<b-button
																	class="btn bg-transparent border-0"
																	size="sm"
																	@click="openEditExpense(slotProps.data)"
																>
																	<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
																</b-button>
															</div>
															<div style="width: 50%; padding: 7px 0" class="d-flex justify-content-center">
																<b-button
																	size="sm"
																	class="btn bg-transparent border-0"
																	v-if="
																		slotProps.data.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
																		slotProps.data.validated === VALIDATION_TYPE.REFUSED_LEVEL_2
																	"
																	@click="undoRejectExpenseItem(slotProps.data.id)"
																>
																	<component :is="getLucideIcon(ICONS.UNDO_2.name)" :color="ICONS.UNDO_2.color" :size="20" />
																</b-button>
																<b-button
																	size="sm"
																	class="btn bg-transparent border-0"
																	@click="rejectExpenseItem(slotProps.data.id)"
																	v-else
																>
																	<component
																		:is="getLucideIcon(ICONS.X_CIRCLE.name)"
																		:color="ICONS.X_CIRCLE.color"
																		:size="20"
																	/>
																</b-button>
															</div>
														</div>
													</template>
												</column>
											</data-table>
										</div>
									</b-col>
								</b-row>
								<b-row :class="`${$screen.width <= 576 ? 'footer-fixed' : ''}`">
									<b-col md="3" sm="12">
										<div class="w-100 d-flex justify-content-center">
											<b-button
												@click="validateExpense"
												size="md"
												variant="outline-success"
												class="d-flex justify-content-center align-items-center"
												:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-90'}`"
											>
												<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="20" class="mr-2" />
												<div style="margin-top: 1px">{{ this.FormMSG(15, 'Validate Expense without rejected items') }}</div>
											</b-button>
										</div>
									</b-col>
									<b-col md="3" sm="12">
										<div class="w-100 d-flex justify-content-center">
											<b-button
												@click="downloadXls"
												size="md"
												variant="outline-primary"
												class="d-flex justify-content-center align-items-center"
												:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-90'}`"
												:disabled="isLoadingDownload"
											>
												<b-spinner v-show="isLoadingDownload" class="mr-2" small></b-spinner>
												<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" v-if="!isLoadingDownload" />
												<div style="margin-top: 1px">{{ this.FormMSG(221, 'Download this Expense') }}</div>
											</b-button>
										</div>
									</b-col>
									<b-col md="3" sm="12">
										<div class="w-100 d-flex justify-content-center">
											<b-button
												@click="sendXlsByEmail"
												size="md"
												variant="outline-primary"
												class="d-flex justify-content-center align-items-center"
												:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-90'}`"
												:disabled="isLoadingSendMail"
											>
												<b-spinner v-show="isLoadingSendMail" class="mr-2" small></b-spinner>
												<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" v-if="!isLoadingSendMail" />
												<div style="margin-top: 1px">{{ this.FormMSG(16, 'Send me this Expense by Email') }}</div>
											</b-button>
										</div>
									</b-col>
									<b-col md="3" sm="12">
										<div class="w-100 d-flex justify-content-center">
											<b-button
												@click="modalRejectExpense = true"
												size="md"
												variant="outline-danger"
												class="d-flex justify-content-center align-items-center"
												:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-90'}`"
											>
												<component :is="getLucideIcon(ICONS.X_CIRCLE.name)" :size="20" class="mr-2" />
												<div style="margin-top: 1px">{{ this.FormMSG(17, 'Reject Expense') }}</div>
											</b-button>
										</div>
									</b-col>
								</b-row>
							</div>
							<b-modal
								header-class="header-class-modal-doc-package"
								:title="FormMSG(18, 'Success!')"
								class="modal-success"
								v-model="successModal"
								@ok="successModal = false"
								ok-variant="success"
								ok-only
								modal-class="mui-animation"
								:fade="false"
							>
								{{ this.FormMSG(19, 'The expense has been sent to your Email address') }}
							</b-modal>
							<b-modal
								header-class="header-class-modal-doc-package"
								:title="FormMSG(71, 'Success!')"
								class="modal-success"
								v-model="successModalValidated"
								@ok="successModalValidated = false"
								ok-variant="success"
								ok-only
								modal-class="mui-animation"
								:fade="false"
							>
								{{ this.FormMSG(23, 'The expense has been validated') }}
							</b-modal>
							<b-modal
								header-class="header-class-modal-doc-package"
								:title="FormMSG(24, 'Refuse this expense item')"
								class="modal-danger"
								v-model="modalRejectExpense"
								@ok="this.finaliseExpenseReject"
								ok-variant="danger"
								ok-only
								modal-class="mui-animation"
								:fade="false"
							>
								<b-form-group :label="FormMSG(25, 'Enter your comment:')" label-for="comment" :label-cols="4">
									<b-form-textarea
										id="comment"
										v-model="rejectComment"
										:placeholder="this.FormMSG(26, 'Enter your comment')"
										rows="3"
									></b-form-textarea>
								</b-form-group>
							</b-modal>
							<b-modal
								header-class="header-class-modal-doc-package"
								:title="FormMSG(24, 'Refuse this expense item')"
								class="modal-danger"
								v-model="modalRejectedExpenseItem"
								@ok="this.finaliseExpenseItemReject"
								ok-variant="danger"
								ok-only
								modal-class="mui-animation"
								:fade="false"
							>
								<b-form-group :label="FormMSG(25, 'Enter your comment:')" label-for="comment" :label-cols="4">
									<b-form-textarea
										id="comment"
										v-model="rejectComment"
										:placeholder="this.FormMSG(26, 'Enter your comment')"
										rows="3"
									></b-form-textarea>
								</b-form-group>
							</b-modal>
							<b-modal
								header-class="header-class-modal-doc-package"
								:title="FormMSG(30, 'Expense item comment')"
								class="modal-danger"
								v-model="modalExpenseItemComment"
								@ok="modalExpenseItemComment = false"
								ok-variant="danger"
								ok-only
								modal-class="mui-animation"
								:fade="false"
							>
								<b-row>
									<b-col cols="12" sm="12">
										<b-form-group :label="FormMSG(31, 'Comment:')" label-for="comment" :label-cols="2">
											<b-form-textarea :disabled="true" id="comment" v-model="expenseItemComment" rows="2"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
							</b-modal>
						</b-card-text>
					</b-card>
				</b-col>
			</b-row>
			<script-export-modal
				v-model="isScriptExpenseReportOpen"
				:items="scripts"
				:custom="customReport"
				export-type="expense"
				@script-export-modal:loading="handleLoading"
				@script-export-modal:closed="expenseRerpotOnClosed"
			/>
			<ExpenseService
				:key="expenseModalRefreshToken"
				v-model="isNewExpenseModalOpen"
				:expense-type="newExpenseType"
				:edit-data="currEditExpense"
				@expense-service-main:error="getError"
				@submited="handleModalSubmited"
			/>
			<expense-carousel-view-modal
				v-model="isExpenseCarouselViewOpen"
				:expense="curExp"
				:expense-item="expenseItem"
				:expense-items="expItems"
				@expense-carousel-view-modal:closed="onExpenseCarouselViewClosed"
				@expense-carousel-view-modal:reload="onExpenseCarouselViewReloaded"
			/>
			<expense-splitting-modal
				v-model="isExpenseSplittingOpen"
				:expense-item="expenseSplittingItem"
				@expense-splitting-modal:closed="onExpenseSplittingClosed"
			/>
		</div>
	</error-container>
</template>

<script>
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import ValidateExpenseDetailsList from '@/components/MobileViews/ValidateExpenseDetails/List';
import { getFileExtension } from '@/shared/helpers';
import { isNil, omit, generateSecureId } from '~utils';
import { mapActions } from 'vuex';
import ExpenseService from '@/components/ExpenseService/Main';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import { classStatus } from '@/shared/global-status';
import globalMixin from '@/mixins/global.mixin';
import { ArrowLeft, Euro, Calendar } from 'lucide-vue';
// import moment from 'moment';
import { getScripts } from '@/cruds/script.crud';
import { expenseReport } from '@/cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getExpenseItems, validateExpense, validateExpenseItem, undoRejectExpenseItem, rejectExpense, rejectExpenseItem } from '@/cruds/expense.crud';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ExpenseCarouselViewModal from '@/components/ExpenseService/ExpenseCarouselViewModal';
import ExpenseSplittingModal from '@/components/ExpenseService/ExpenseSplittingModal';
import { getProjectFlags } from '@/cruds/flag.crud';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT, EXPENSE_TYPE, VALIDATION_TYPE } from '@/shared/constants';

export default {
	name: 'ValidateExpDetails',
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		ValidateExpenseDetailsList,
		ExpenseService,
		ArrowLeft,
		Euro,
		Calendar,
		ScriptExportModal,
		DataTable,
		Column,
		ExpenseCarouselViewModal,
		ExpenseSplittingModal
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			curExp: {
				id: 0,
				date: '',
				amountTotal: 0,
				validatedStatus: 'Not Submited'
			},
			curMonthStr: '',
			oneItem: '',
			expItems: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			curExpenseItemRejected: 0,
			successModal: false,
			successModalValidated: false,
			modalRejectedExpenseItem: false,
			modalRejectExpense: false,
			modalExpenseItemComment: false,
			expenseItemComment: '',
			successModalRejectedExpense: false,
			successModalRejectedExpItem: false,
			rejectComment: '',
			filter: '',
			isLoading: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curExp: {
				id: 0,
				date: '',
				type: 0,
				amountTotal: 0,
				validated: 0,
				validatedStatus: 'Not Submited',
				typeString: ''
			},
			currEditExpense: null,
			isNewExpenseModalOpen: false,
			newExpenseType: 2, // default km mileage
			expenseModalRefreshToken: 0,
			isLoadingDownload: false,
			isLoadingSendMail: false,
			isScriptExpenseReportOpen: false,
			scriptTypeId: 0,
			scripts: [],
			customReport: null,
			isExpenseCarouselViewOpen: false,
			expenseItem: null,
			isExpenseSplittingOpen: false,
			expenseSplittingItem: null,
			flags: [],
			taxes: [],
			customFlagKeys: [],
			ticketFields: null,
			EXPENSE_TYPE: EXPENSE_TYPE,
			VALIDATION_TYPE: VALIDATION_TYPE
		};
	},
	computed: {
		canEditExpense() {
			return store.canEditExpense();
		},
		cTicketFields() {
			return [
				{
					key: 'id',
					label: '#' + this.FormMSG(52, 'Id'),
					sortable: false,
					class: 'text-center',
					flexBasis: '6%'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(51, 'Status'),
					sortable: false,
					class: 'text-center',
					isDataTableSlot: true,
					flexBasis: '12%'
				},
				{
					key: 'date',
					label: this.FormMSG(53, 'Date'),
					sortable: false,
					class: 'text-center',
					isDataTableSlot: true,
					flexBasis: '8%'
				},
				{
					key: 'description',
					label: this.FormMSG(54, 'Description'),
					sortable: false,
					flexBasis: '12%'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(58, 'Department'),
					sortable: false,
					class: 'text-center',
					flexBasis: '10%'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(59, 'Category'),
					sortable: false,
					class: 'text-center',
					flexBasis: '10%'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(60, 'Supplier'),
					sortable: false,
					class: 'text-center',
					flexBasis: '10%'
				},
				{
					key: 'paiementTypeName',
					label: this.FormMSG(76, 'Payment'),
					sortable: false,
					class: 'text-center',
					flexBasis: '10%'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					class: 'text-right',
					sortable: false,
					isDataTableSlot: true,
					flexBasis: '8%'
				},
				{
					key: 'amount',
					label: this.FormMSG(79, 'Amount (tax excl.)'),
					sortable: false,
					class: 'text-right',
					isDataTableSlot: true,
					flexBasis: '10%',
					backgroundColor: '#C51605 !important'
				},
				// {
				// 	key: 'vatCode',
				// 	label: this.FormMSG(78, 'VAT Code'),
				// 	sortable: false,
				// 	class: 'text-center',
				// 	flexBasis: '10%',
				// 	backgroundColor: '#4373c6 !important'
				// },
				{
					key: 'amountVat',
					label: this.FormMSG(1, 'VAT'),
					formatter: (value) => rendCurrency(value),
					sortable: false,
					class: 'text-right',
					isDataTableSlot: true,
					flexBasis: '8%',
					backgroundColor: '#C51605 !important'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(77, 'Total Amount'),
					formatter: (value) => rendCurrency(value),
					sortable: false,
					class: 'text-right',
					isDataTableSlot: true,
					flexBasis: '10%',
					backgroundColor: '#C51605 !important'
				},
				// {
				// 	key: 'splitVat',
				// 	label: this.FormMSG(80, 'Split VAT'),
				// 	sortable: false,
				// 	class: 'text-center',
				// 	isDataTableSlot: true,
				// 	flexBasis: '7%'
				// },
				{
					key: 'pict',
					label: this.FormMSG(63, 'Picture'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true,
					isDataTableSlot: true,
					isDataTableAction: true
				},
				{
					key: 'expenseItem',
					label: this.FormMSG(75, 'Edit'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true,
					isDataTableSlot: true,
					isDataTableAction: true
				},
				{
					key: 'rej',
					label: this.FormMSG(64, 'Refuse'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true,
					isDataTableSlot: true,
					isDataTableAction: true
				},
				{
					key: 'actions',
					class: 'text-center',
					sortable: false,
					isDataTableSlot: true
				}
			];
		},
		kmFields() {
			return [
				{
					key: 'id',
					label: '#' + this.FormMSG(52, 'Id'),
					sortable: true,
					class: 'text-center',
					flexBasis: '6%'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(51, 'Status'),
					sortable: false,
					class: 'text-center',
					isDataTableSlot: true,
					flexBasis: '10%'
				},
				{
					key: 'date',
					label: this.FormMSG(53, 'Date'),
					formatter: (value) => {
						return this.dateToStringFormat(value);
					},
					sortable: true,
					class: 'text-center',
					isDataTableSlot: true,
					flexBasis: '8%'
				},
				{
					key: 'description',
					label: this.FormMSG(54, 'Description'),
					sortable: true,
					flexBasis: '12%'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(58, 'Department'),
					sortable: true,
					class: 'text-center',
					flexBasis: '10%'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(59, 'Category'),
					sortable: true,
					class: 'text-center',
					flexBasis: '10%'
				},
				{
					key: 'fromLocation',
					label: this.FormMSG(65, 'From'),
					sortable: true,
					class: 'text-center',
					flexBasis: '12%'
				},
				{
					key: 'toLocation',
					label: this.FormMSG(66, 'To'),
					sortable: true,
					class: 'text-center',
					flexBasis: '12%'
				},
				{
					key: 'km',
					label: this.FormMSG(67, 'Distance'),
					formatter: (value) => {
						return `${recalculateDistanceValueByUnit(value)} ${store.state.myProfile.distanceUnit}`;
					},
					class: 'text-right',
					sortable: true,
					isDataTableSlot: true,
					flexBasis: '8%'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(61, 'Amount'),
					formatter: (value) => rendCurrency(value),
					sortable: true,
					class: 'text-right',
					isDataTableSlot: true,
					flexBasis: '8%'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					class: 'text-right',
					sortable: true,
					isDataTableSlot: true,
					flexBasis: '8%'
				},
				{
					key: 'expenseItem',
					label: this.FormMSG(75, 'Edit'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true,
					isDataTableSlot: true,
					isDataTableAction: true
				},
				{
					key: 'rej',
					label: this.FormMSG(64, 'Refuse'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true,
					isDataTableSlot: true,
					isDataTableAction: true
				}
			];
		},
		caption1() {
			if (isNil(this.curExp)) return '';
			const cap = this.FormMSG(10, 'Expense details for') + ' ' + this.curExp.typeString.toUpperCase() + ' #' + this.curExp.id;
			//  +
			// ':   ' +
			// this.curExp.fullName +
			// '  - ' +
			// this.curExp.functionName;
			return cap;
		},
		isProd() {
			return store.isProd();
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		useEntryForCrewExpenses() {
			return store.state.myProject.useEntryForCrewExpenses;
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		tooltipContentOvertime() {
			let message = `${this.FormMSG(74, "This expense item is on a date outside the user's contract dates. You can still validate it.")}`;
			return message;
		},
		isRefused(item) {
			return item.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 || item.validated === VALIDATION_TYPE.REFUSED_LEVEL_2;
		},
		getCurrency() {
			return store.state.myProfile.currencySymbol;
		},
		rowClicked(item) {
			//console.log("item.comment :", item.comment);
			if (item.comment.length > 0) {
				this.expenseItemComment = item.comment;
				this.modalExpenseItemComment = true;
			}
		},
		rejectExpenseItem(id) {
			this.curExpenseItemRejected = id;
			this.rejectComment = '';
			this.modalRejectedExpenseItem = true;
		},
		/**
		 * @param {Array} image
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true
				}
			});
		},
		async finaliseExpenseItemReject() {
			this.modalRejectedExpenseItem = false;
			const expenseItemId = +this.curExpenseItemRejected;
			const comment = this.rejectComment;

			await rejectExpenseItem(expenseItemId, comment)
				.then(async (record) => {
					await this.getNotifications();

					if (this.expItems.length === 1) {
						this.$router.go(-1);
					} else {
						await this.reloadData();
					}

					this.curExp.amountTotal = record.allItemsTotal;
					this.rejectComment = '';
					this.curExpenseItemRejected = 0;
					this.successModalRejectedExpItem = true;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async finaliseExpenseReject() {
			this.modalRejectExpense = false;
			const expenseId = +this.$route.params.id;
			const comment = this.rejectComment;
			await rejectExpense(expenseId, comment)
				.then(async (record) => {
					await this.getNotifications();
					// put updated value in curExp structure
					this.curExp.amountTotal = record.amountTotal;
					this.curExp.comment = record.comment;
					this.curExp.validated = record.validated;
					this.curExp.validatedStatus = this.validatedText(this.curExp.validated);

					this.rejectComment = '';
					this.successModalRejectedExpense = true;
					// go back to expense list
					this.$router.go(-1);
					//this.reloadData();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async validateExpenseItem(id) {
			const expenseItemId = +id;

			await validateExpenseItem(expenseItemId)
				.then(async (record) => {
					await this.reloadData();
					this.curExp.amountTotal = record.allItemsTotal;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async undoRejectExpenseItem(id) {
			const expenseItemId = +id;

			await undoRejectExpenseItem(expenseItemId)
				.then(async (record) => {
					await this.getNotifications();
					await this.reloadData();
					this.curExp.amountTotal = record.allItemsTotal;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async validateExpense() {
			const expenseId = +this.$route.params.id;

			await validateExpense(expenseId, '')
				.then(async (record) => {
					await this.getNotifications();
					await this.reloadData();
					this.curExp.amountTotal = record.amountTotal;
					this.curExp.comment = record.comment;
					this.curExp.validated = record.validated;
					this.curExp.validatedStatus = this.validatedText(this.curExp.validated);
					this.successModalValidated = true;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
			this.curExp = store.getCurExpense();
			// console.log("this.curExp:", this.curExp)
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(expItemData) {
			// setting row color
			expItemData.validatedStatus = this.validatedText(expItemData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			//expItemData._rowVariant = this.validatedColor(expItemData.validated);
			expItemData.validatedClass = this.validatedColor(expItemData.validated);
			expItemData.statusClass = classStatus(expItemData.validated);
			expItemData.date = expItemData.date.substring(0, 10) + this.updateTimeToTZ(expItemData.date.substring(10));
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated === VALIDATION_TYPE.SUBMITTED) {
				var retval = 'primary';
			} else if (validated === VALIDATION_TYPE.VALIDATED_LEVEL_1) {
				var retval = 'warning';
			} else if (validated === VALIDATION_TYPE.VALIDATED_LEVEL_2) {
				var retval = 'success';
			} else if (validated === VALIDATION_TYPE.REFUSED_LEVEL_1 || validated === VALIDATION_TYPE.REFUSED_LEVEL_2) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		async reloadData() {
			this.isLoading = true;
			const expenseId = +this.$route.params.id;

			await getExpenseItems(expenseId)
				.then((records) => {
					records.forEach(this.updateTZdataEach);
					this.expItems = records;
					// console.log({ expItems: this.expItems });
					// console.log({ customFlagKeys2nd: this.customFlagKeys });
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTypeId, true);
			const id = +this.curExp.id;

			if (this.scripts.length === 0) {
				await this.manageExpenseReport(id, 0, true).then(() => {
					this.createToastForMobile(this.FormMSG(72, 'Success'), this.FormMSG(476, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.manageExpenseReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(72, 'Success'), this.FormMSG(476, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.isLoading = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTypeId, true);
			const id = +this.curExp.id;

			if (this.scripts.length === 0) {
				await this.manageExpenseReport(id).finally(() => {
					this.isLoadingDownload = false;
				});
			} else if (this.scripts.length === 1) {
				await this.manageExpenseReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoadingDownload = false;
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async manageExpenseReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, script.id, script.fileName);

			await expenseReport(
				{
					subjectId,
					sendEmail,
					activeStatus: true
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		async handleModalSubmited() {
			this.expenseModalRefreshToken = this.expenseModalRefreshToken + 1;
			this.$toast({
				message: this.FormMSG(71, 'Your expense was updated successfully.'),
				title: this.FormMSG(72, 'Success'),
				variant: 'success'
			});

			this.isNewExpenseModalOpen = false;
			await this.reloadData();
		},
		openEditExpense(expense) {
			if (expense.type === EXPENSE_TYPE.TRAVEL) {
				expense.originalKm = expense.km;
				expense.originalFromLocation = expense.fromLocation;
				expense.originalToLocation = expense.toLocation;
			} else {
				expense.originalImages = expense.images;
			}
			this.newExpenseType = expense.type;
			this.currEditExpense = expense;
			this.isNewExpenseModalOpen = true;
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		labelStatus(status) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, status);
		},
		omit(keys, objects) {
			return objects.filter((object) => !keys.includes(object.key));
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
				this.isLoadingDownload = false;
			}
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.isLoadingSendMail = false;
			this.isLoadingDownload = false;
		},
		onTicketRowDblclick(payload) {
			// console.log({ payload });
			this.expenseItem = payload;
			this.isExpenseCarouselViewOpen = true;
		},
		onTravelRowDblclick(payload) {
			// console.log({ payload });
			this.expenseItem = payload;
			this.isExpenseCarouselViewOpen = true;
		},
		onExpenseCarouselViewClosed() {
			this.isExpenseCarouselViewOpen = false;
		},
		getDistanceTotalByUnit(value) {
			return recalculateDistanceValueByUnit(value);
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		},
		onExpenseItemSplitted(payload) {
			console.log({ payload });
			this.expenseSplittingItem = payload.data;
			this.isExpenseSplittingOpen = true;
		},
		onExpenseSplittingClosed() {
			this.isExpenseSplittingOpen = false;
		},
		async getProjectFlagsByType() {
			try {
				return await getProjectFlags();
			} catch (error) {
				console.log({ error });
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		async onExpenseCarouselViewReloaded() {
			this.isExpenseCarouselViewOpen = false;
			await this.reloadData();
		}
	},
	async created() {
		if (this.curExp.date.length === 0) {
			this.firstTimeInitialisation();
		}

		const projectFlags = await this.getProjectFlagsByType();
		this.flags = projectFlags.customFlags;
		this.taxes = projectFlags.taxeFlags;
	},
	watch: {
		cTicketFields: {
			async handler(value) {
				const flagKeys = async () => {
					return await new Promise((resolve) => {
						let flags = [];
						setTimeout(() => {
							for (let i = 0; i < this.flags.length; i++) {
								flags.push({
									key: this.generateTextId(this.flags[i].short + '-' + i),
									label: this.flags[i].short,
									sortable: false,
									backgroundColor: '#cd9a16 !important',
									flexBasis: '10%'
								});
							}
							resolve(flags);
						}, 250);
					});
				};

				await flagKeys().then((records) => {
					this.customFlagKeys = records;
				});

				await this.reloadData();

				let ticketFields = value;
				/**
				 * disable temporary this line,
				 * and uncomment this when reactive
				 * split TVA
				 */
				// ticketFields.splice(12, 0, ...this.customFlagKeys);
				this.ticketFields = ticketFields;
			}
		},
		deep: true,
		immediate: true
	}
};
</script>

<style lang="scss" scoped></style>
