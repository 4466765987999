var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "expenseCarouselView",
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                      _vm._v(_vm._s(_vm.FormMSG(3, "Expense TVA Splitting"))),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "4" } },
                    [
                      _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(
                                4 + _vm.expenseItem.type,
                                _vm.EXPENSE_NAME[_vm.expenseItem.type]
                              )
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t#" +
                            _vm._s(_vm.expenseItem ? _vm.expenseItem.id : "") +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                  _c("b-col", { attrs: { cols: "4" } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px ml-3",
                      staticStyle: { "margin-top": "0px" },
                      attrs: {
                        size: "sm",
                        variant: "outline-primary",
                        block: "",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Save")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
        },
        [
          _vm.expenseItem
            ? _c(
                "b-row",
                { staticClass: "form" },
                [
                  _c(
                    "b-col",
                    { staticClass: "px-0", attrs: { sm: "8" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sub-title pb-3",
                          staticStyle: { "margin-left": "0" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(5, "Initial Expense item")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "px-3 pb-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Amount (tax excl.)"),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.amount,
                                          type: "number",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(7, "Total VAT") },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.amountVat,
                                          type: "number",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      8,
                                      "Total amount (tax incl.)"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.amountTotal,
                                          type: "number",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "sub-title pb-3",
                          staticStyle: { "margin-left": "0" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(9, "New splitting")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "pb-2" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "7.25rem",
                                    color: "rgba(6, 38, 62, 0.64)",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          11,
                                          "Note: initial expense item will be splitted into different lines"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "px-5" },
                                    [
                                      _c(
                                        "b-table-simple",
                                        {
                                          attrs: {
                                            small: "",
                                            id: "expense-splitting",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-thead",
                                            {
                                              attrs: { "head-variant": "dark" },
                                            },
                                            [
                                              _c(
                                                "b-tr",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: {
                                                    "line-height": "24px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        border:
                                                          "none !important",
                                                        "background-color":
                                                          "transparent !important",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          12,
                                                          "Amount (tax excl.)"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          13,
                                                          "VAT Code"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          14,
                                                          "VAT Amount"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          15,
                                                          "Total amount (tax incl.)"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("b-th", [_vm._v(" ")]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tbody",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "b-tr",
                                                {
                                                  staticStyle: {
                                                    "line-height": "24px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-td",
                                                    {
                                                      staticStyle: {
                                                        border:
                                                          "none !important",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("b-td", [
                                                    _vm._v("303.2 €"),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [_vm._v("T20")]
                                                  ),
                                                  _c("b-td", [
                                                    _vm._v("75.8 €"),
                                                  ]),
                                                  _c("b-td", [_vm._v("379 €")]),
                                                  _c(
                                                    "b-td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.TRASH.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass:
                                                            "cursor-pointer",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.TRASH
                                                                .color,
                                                            "stroke-width": 2.5,
                                                            size: 18,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tfoot",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "b-tr",
                                                {
                                                  staticStyle: {
                                                    "font-size": "1rem",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticClass: "text-left",
                                                      staticStyle: {
                                                        "border-top":
                                                          "0.005rem solid rgba(226, 230, 235, 0.85) !important",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            16,
                                                            "TOTAL"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("b-th", [
                                                    _vm._v("1,303.20 €"),
                                                  ]),
                                                  _c(
                                                    "b-th",
                                                    { attrs: { colspan: "2" } },
                                                    [_vm._v("75.80 €")]
                                                  ),
                                                  _c("b-th", [
                                                    _vm._v("1,379.00 €"),
                                                  ]),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticClass: "text-left",
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-row", { staticClass: "px-5" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end w-100" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "w-138-px d-flex justify-content-center align-items-center",
                                attrs: {
                                  size: "sm",
                                  variant: "outline-primary",
                                },
                              },
                              [
                                _c(_vm.getLucideIcon("PlusCircle"), {
                                  tag: "component",
                                  attrs: { size: 16, "stroke-width": 2 },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "px-2",
                                    staticStyle: { "margin-bottom": "-2px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(10, "Add Splitting")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticStyle: {
                        border: "1px solid #47c7bf",
                        "border-radius": "8px",
                      },
                      attrs: { sm: "4" },
                    },
                    [
                      _vm.expenseItem.images.length > 0
                        ? _c("carousel", {
                            attrs: {
                              value: _vm.expenseItem.images,
                              "num-visible": 1,
                              "num-scroll": 1,
                              orientation: "vertical",
                              verticalViewPortHeight: "200px",
                              contentClass: "main-carousel",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (slotProps) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "parent cursor-pointer",
                                        },
                                        [
                                          _c("div", { staticClass: "child" }, [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-transparent",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture(
                                                      slotProps
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Eye"), {
                                                  tag: "component",
                                                  staticStyle: {
                                                    "margin-top": "4px",
                                                  },
                                                  attrs: {
                                                    size: 22,
                                                    "stroke-width": 2,
                                                    color: "#00A09C",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-burning-tomato",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePicture(
                                                      slotProps
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon("Trash2"),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      size: 22,
                                                      "stroke-width": 2,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("b-img-lazy", {
                                            staticClass: "px-1",
                                            staticStyle: {
                                              "border-radius": "8px",
                                            },
                                            attrs: {
                                              src: _vm.getImage(slotProps.data),
                                              alt: slotProps.data,
                                              "fluid-grow": "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1090556148
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }